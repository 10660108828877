import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>CRM Kami</h2>
          <p>
          Manfaat CRM meliputi peningkatan kepuasan pelanggan, efisiensi dalam pengelolaan data, serta peningkatan penjualan dan loyalitas pelanggan. CRM juga membantu perusahaan untuk memahami kebutuhan pelanggan, mempersonalisasi layanan, dan memudahkan tim dalam berkolaborasi untuk memberikan pelayanan yang lebih baik..
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
