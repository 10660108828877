import React from "react";

export const Button = (props) => {
    return (
        <div id="waButton" className="text-center">
            <a href="https://wa.me/6285190015154?text=Hello" target="_blank">
                <img src="img/whatsapp.png" alt="" width="60px"/>
            </a>
        </div>
    );
};
